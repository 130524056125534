.games {
  .card {
    height: 200px;
  }
  .card-body {
    overflow: hidden;
  }
  > div {
    margin-bottom: 10px;
  }
  .card-subtitle {
    text-align: right;
  }
  .card.team {
    background: #eeeeee;
  }
}